/* src/App.css */
.container {
  padding-left: 192px;  /* Adds 2 inches of space on the left */
  padding-right: 192px; /* Adds 2 inches of space on the right */
}

@media (max-width: 1200px) {
  /* For smaller screens, reduce the padding to keep the design responsive */
  .container {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (max-width: 768px) {
  /* On mobile, remove the large padding */
  .container {
    padding-left: 0px;
    padding-right: 0px;
  }
}
