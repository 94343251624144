/* Navbar container */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 0;
    background-color: white;
}

/* Center: Logo and Navigation Links */
.navbar-logo img {
    height: 40px;
}

.navbar-links {
    display: flex;
    gap: 20px;
    list-style-type: none;
}

.navbar-links a {
    text-decoration: none;
    color: black;
    font-weight: bold;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 20px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.navbar-links a:hover {
    color: white;
    background-color: black;
}

.navbar-links a.active {
    color: white;
    background-color: black;
}

/* Right side: Support, For Business, and Icons */
.right-side {
    display: flex;
    align-items: center;
    gap: 20px;
}

.right-links {
    display: flex;
    gap: 20px;
    list-style-type: none;
}

.right-links a {
    text-decoration: none;
    color: black;
    font-weight: bold;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 20px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.right-links a:hover {
    color: white;
    background-color: black;
}

/* Icons: Search, Cart, and User */
.navbar-icons {
    display: flex;
    gap: 15px;
}

.navbar-icons img {
    height: 24px;
    width: 24px;
    cursor: pointer;
}

/* Mobile menu icon */
.menu-icon {
    display: none;
    height: 24px;
    cursor: pointer;
}

/* Mobile Menu */
.mobile-menu {
    position: absolute;
    right: 0;
    top: 60px;
    background-color: white;
    width: 70%;
    height: 100vh;
    box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.2);
    padding: 20px;
    z-index: 100;
}

.mobile-menu ul {
    list-style-type: none;
    padding: 0;
}

.mobile-menu ul li {
    margin-bottom: 15px;
}

.mobile-menu ul li a {
    text-decoration: none;
    color: black;
    font-weight: bold;
    font-size: 18px;
    padding: 10px;
    display: block;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.mobile-menu ul li a:hover {
    background-color: black;
    color: white;
}

/* Responsive Media Queries */

/* Common Mobile Screen Sizes */
@media only screen and (max-width: 768px) {
    /* Hide desktop menu links on mobile */
    .navbar-links, .right-links {
        display: none;
    }

    /* Show menu icon on mobile */
    .menu-icon {
        display: inline-block;
    }

    .navbar-icons {
        gap: 10px;
    }
}

/* iPhone 12/13 Pro Max, Samsung S21/S22 */
@media only screen and (max-width: 430px) {
    .navbar-logo img {
        height: 30px;
        padding: 5px;
    }

    .navbar-icons img {
        height: 20px;
        width: 20px;
        padding-right: 5px;
    }

    .mobile-menu ul li a {
        font-size: 16px;
        padding: 8px;
    }
}

/* iPhone SE, iPhone 8, iPhone X, Google Pixel 4a */
@media only screen and (max-width: 375px) {
    .navbar-logo img {
        height: 28px;
        padding-left: 5px;
    }

    .navbar-icons img {
        height: 18px;
        width: 18px;
        padding-right: 5px;
    }

    .mobile-menu ul li a {
        font-size: 15px;
        padding: 8px;
    }
}

/* Samsung Galaxy A50, Redmi Note 10, Vivo V21 */
@media only screen and (max-width: 414px) {
    .navbar-logo img {
        height: 32px;
        padding-left: 5px;
    }

    .navbar-icons img {
        height: 22px;
        width: 22px;
        padding-right: 5px;
    }

    .mobile-menu ul li a {
        font-size: 16px;
        padding: 10px;
    }
}

/* Sony Xperia, LG Velvet */
@media only screen and (max-width: 360px) {
    .navbar-logo img {
        height: 26px;
        padding-left: 5px;
    }

    .navbar-icons img {
        height: 18px;
        width: 18px;
        padding-right: 5px;
    }

    .mobile-menu ul li a {
        font-size: 14px;
        padding: 6px;
    }
}

/* Google Pixel XL, Pixel 6 */
@media only screen and (max-width: 411px) {
    .navbar-logo img {
        height: 34px;
        padding-left: 5px;
        padding-right: 5px;
    }

    .navbar-icons img {
        height: 24px;
        width: 24px;
    }

    .mobile-menu ul li a {
        font-size: 16px;
        padding: 10px;
    }
}
/* Surface Pro 7 (2736x1824) */
@media only screen and (max-width: 1824px) {
    .navbar-logo img {
        height: 32px;
        padding-left: 5px;
    }

    .navbar-icons img {
        height: 24px;
        width: 24px;
        padding-right: 5px;
    }

    .mobile-menu ul li a {
        font-size: 18px;
        padding: 12px;
    }
}

/* Surface Duo (1800x1350 - folded) */
@media only screen and (max-width: 1350px) {
    .navbar-logo img {
        height: 30px;
        padding-left: 5px;
        padding-right: 5px;
    }

    .navbar-icons img {
        height: 22px;
        width: 22px;
    }

    .mobile-menu ul li a {
        font-size: 12px;
        padding: 5px;
    }
}

/* Galaxy Z Fold 5 (1812x2176 - folded) */
@media only screen and (max-width: 1812px) {
    .navbar-logo img {
        height: 28px;
        padding: 5px;
        
    }

    .navbar-icons img {
        height: 20px;
        width: 20px;
        padding-right: 5px;
    }
    

    .mobile-menu ul li a {
        font-size: 16px;
        padding: 8px;
    }
}

/* Asus Zenbook Fold (1920x1080) */
@media only screen and (max-width: 1080px) {
    .navbar-logo img {
        height: 36px;
        padding: 5px;
    }

    .navbar-icons img {
        height: 26px;
        width: 26px;
        padding-right: 5px;
    }

    .mobile-menu ul li a {
        font-size: 18px;
        padding: 12px;
    }
}

/* Samsung Galaxy A51/A71 (1080x2400) */
@media only screen and (max-width: 1080px) {
    .navbar-logo img {
        height: 30px;
        padding: 5px;
    }

    .navbar-icons img {
        height: 22px;
        width: 22px;
        padding-right: 5px;
    }

    .mobile-menu ul li a {
        font-size: 16px;
        padding: 10px;
    }
}

/* Nest Hub (1024x600) */
@media only screen and (max-width: 1024px) {
    .navbar-logo img {
        height: 26px;
        padding: 5px;
    }

    .navbar-icons img {
        height: 18px;
        width: 18px;
        padding-right: 5px;
    }

    .mobile-menu ul li a {
        font-size: 6px;
        padding: 2px;
    }
}

/* Nest Hub Max (1280x800) */
@media only screen and (max-width: 1280px) {
    .navbar-logo img {
        height: 28px;
        padding: 5px;
    }

    .navbar-icons img {
        height: 20px;
        width: 20px;
        padding-right: 5px;
    }

    .mobile-menu ul li a {
        font-size: 15px;
        padding: 8px;
    }
}
