.service-page {
  text-align: center;
  padding: 20px;
  background-color: #E8E8E8;
  color: rgb(0, 0, 0);
  margin-top: 60px;
  margin-bottom: 20px;
}

.service-title {
  font-size: 36px;
  margin-bottom: 20px;
  font-family: 'Arial', sans-serif;
}

/* Carousel Setup */
.carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-container {
  width: 80%; /* Center the container */
  overflow: hidden;
}

.carousel-row {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.carousel-item {
  flex: 0 0 187.72px; /* Each image takes 187.72px width */
  padding: 10px;
  display: flex;
  justify-content: center;
  transition: transform 0.5s ease-in-out, border-radius 0.5s ease-in-out; /* Add smooth transition for transform */
}

.service-image {
  width: 187.72px;
  height: 428.14px;
  object-fit: cover;
  border-radius: 15px;
  background-color: #333; /* Fallback background */
  transition: transform 0.5s ease-in-out, border-radius 0.5s ease-in-out; /* Smooth scale transition */
}



/* Navigation Buttons */
.prev-arrow, .next-arrow {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10; /* Ensure the arrows are above the carousel */
}

.prev-arrow {
  left: 10px;
}

.next-arrow {
  right: 10px;
}

.prev-arrow:hover, .next-arrow:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Responsive Design for Mobile View */
@media (max-width: 768px) {
  .service-page {
    margin-top: -10px;
  }
  .carousel-item {
    flex: 0 0 auto; /* Allow free scrolling of all images */
  }

  .service-image {
    width: 187.72px; /* Same width as desktop */
    height: 428.14px; /* Same height as desktop */
  }

  /* Hide Next/Previous Buttons in Mobile View */
  .prev-arrow, .next-arrow {
    display: none; /* Remove next/prev buttons */
  }

  /* Adjust Carousel to Make Space for Dots */
  .carousel {
    position: relative;
    padding-bottom: 40px; /* Add space below the carousel for the dots */
  }

  /* Dot Indicators for Mobile View */
  .dots-container {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0; /* Align dots at the bottom of the carousel */
    left: 50%;
    transform: translateX(-50%); /* Center the dots horizontally */
  }

  .dot {
    height: 10px;
    width: 10px;
    margin: 0 4px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.3s ease;
    cursor: pointer;
  }

  .active-dot {
    background-color: rgba(0, 0, 0, 0.9); /* Darker color for active dot */
  }

  /* Horizontal Scrolling for Images */
  .carousel-container {
    display: flex;
    overflow-x: auto; /* Allow horizontal scrolling */
    scroll-snap-type: x mandatory; /* Smooth snap scroll for each image */
  }

  .carousel-item {
    scroll-snap-align: start; /* Snap each image into view */
    margin-right: 10px; /* Add a small margin between images */
  }

  .carousel-container::-webkit-scrollbar {
    display: none; /* Hide scrollbar for a cleaner look */
  }
}
