.footer-container {
    background-color: #000;
    color: #fff;
    padding: 40px 20px;
    font-family: Arial, sans-serif;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-logo-section {
    flex: 1;
    text-align: center;
    min-width: 200px;
  }
  
  .footer-logo {
    width: 100px;
    margin-bottom: 10px;
  }
  
  .footer-description {
    color: #ffffff;
    font-size: 14px;
    margin-top: 10px;
  }
  
  .footer-links-section,
  .footer-contact-section,
  .footer-social-section {
    flex: 1;
    min-width: 150px;
    text-align: center;
    margin-top: 20px;
  }
  
  .footer-heading {
    font-size: 16px;
    color: #ffffff;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
  
  .footer-links-section ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-links-section li {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;
    justify-content: center; /* Center links horizontally */
  }
  
  .footer-links-section .icon {
    color: #ffb400;
    margin-right: 8px;
  }
  
  .footer-contact-section p {
    font-size: 14px;
    color: #fff;
    margin: 8px 0;
    text-align: center; /* Center text */
  }
  
  .footer-contact-section .icon {
    color: #ffb400;
    margin-right: 8px;
  }
  
  .footer-social-section p {
    color: #aaa;
    font-size: 14px;
    margin-top: 10px;
    text-align: center; /* Center text */
  }
  
  .social-icons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
  }
  
  .social-icons a {
    color: #ffb400;
    font-size: 20px;
    text-decoration: none;
    transition: transform 0.3s ease;
  }
  
  .social-icons a:hover {
    transform: scale(1.1);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      align-items: center; /* Center items vertically */
      text-align: center; /* Center text within each section */
    }
  
    .footer-logo-section,
    .footer-links-section,
    .footer-contact-section,
    .footer-social-section {
      margin-bottom: 20px;
      text-align: center; /* Ensure text is centered */
    }
  }
  