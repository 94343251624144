/* Full-page container */
.welcome-page-container {
    font-family: Arial, sans-serif;
    color: #333;
    padding: 40px;
    text-align: center;
    background-color: #ffffff; /* Full page background color */
  }
  
  /* Main heading section */
  .welcome-intro-section {
    margin-bottom: 40px;
  }
  
  .welcome-main-heading {
    font-size: 24px;
    font-weight: bold;
    color: #E60023;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .welcome-subheading {
    font-size: 36px;
    font-weight: bold;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .welcome-description {
    font-size: 16px;
    max-width: 600px;
    margin: 20px auto;
    line-height: 1.6;
    text-align: center; /* Justify the main intro paragraph */
  }
  
  /* Explore section */
  .welcome-explore-section {
    margin: 40px 0;
  }
  
  .welcome-explore-heading {
    font-size: 24px;
    font-weight: bold;
  }
  
  /* Featured service section */
  .welcome-feature-section {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 40px;
  }
  
  .welcome-feature-text {
    max-width: 600px;
    margin-right: 20px;
    text-align: left;
  }
  
  .welcome-feature-subtitle {
    color: #d9534f; /* Red color for highlight */
    font-weight: bold;
  }
  
  .welcome-feature-title {
    font-size: 28px;
    font-weight: bold;
    margin: 10px 0;
  }
  
  .welcome-feature-description {
    font-size: 16px;
    line-height: 1.6;
    text-align: center; /* Fully justify the featured service paragraph */
  }
  
  .welcome-feature-image-container {
    display: flex;
    align-items: center;
  }
  
  .welcome-feature-image {
    max-width: 400px;
    border-radius: 8px;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .welcome-feature-section {
      flex-direction: column;
      align-items: center;
    }
    
    .welcome-feature-text, .welcome-feature-image {
      max-width: 100%;
      margin: 0;
    }
  }
  