.get-in-touch-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
    background-color: #ffffff;
    color: #fff;
    font-family: Arial, sans-serif;
  }
  
  .get-in-touch-content {
    max-width: 500px;
    text-align: center;
  }
  
  .get-in-touch-subheading {
    font-size: 14px;
    color: #000000;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 10px;
  }
  
  .get-in-touch-heading {
    font-size: 36px;
    font-weight: bold;
    color: #E60023;
    margin: 0;
    padding: 0;
  }
  
  .get-in-touch-description {
    font-size: 16px;
    color: #000000;
    margin: 10px 0 30px;
  }
  
  .contact-details {
    margin-bottom: 30px;
  }
  
  .contact-item {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #000000;
    margin: 10px 0;
  }
  
  .contact-item span {
    font-size: 20px;
    margin-right: 10px;
    color: #ffb400;
  }
  
  .social-icons {
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  
  .social-icons a img {
    width: 30px;
    height: 30px;
    transition: transform 0.3s ease;
  }
  
  .social-icons a img:hover {
    transform: scale(1.1);
  }
  