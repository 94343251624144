/* General Size Chart container styles */
.size-chart-container {
    text-align: center;
    padding: 20px;
    background-color: #ffffff;
  }
  
  .size-chart-section {
    margin-bottom: 40px;
  }
  
  .size-chart-subtitle {
    font-size: 1.2rem;
    color: #007bff;
  }
  
  .size-chart-title {
    font-size: 3rem;
    font-weight: bold;
    margin: 10px 0;
  }
  
  .size-chart-grid {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    justify-items: center;
  }
  
  .size-chart-item img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .size-chart-item img:hover {
    transform: scale(1.05);
  }
  
  /* Popup Styles */
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-content {
    position: relative;
    max-width: 90%;
    max-height: 90%;
    overflow: hidden; /* Prevent scrolling inside the popup */
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    animation: fadeIn 0.3s ease;
  }
  
  .popup-content img {
    width: auto; /* Original width */
    height: auto; /* Original height */
    max-width: 100%; /* Fit horizontally in the viewport */
    max-height: 100%; /* Fit vertically in the viewport */
    display: block;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    cursor: pointer;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .close-button:hover {
    background-color: rgba(0, 0, 0, 0.9);
  }
  
  /* Animation for popup */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  