/* Container and header styling */
.gallery-container {
    text-align: center;
    padding: 20px;
    background-color: #ffffff; /* Light background for contrast */
    padding-bottom: 260px;
  }
  
  .gallery-header {
    margin-bottom: 20px;
  }
  
  .gallery-subtitle {
    font-size: 1.2rem;
    color: #E60023;
  }
  
  .gallery-title {
    font-size: 3rem;
    font-weight: bold;
    margin: 10px 0;
  }
  
  .gallery-hashtag {
    font-size: 1rem;
    color: #E60023;
  }
  
  /* Grid styling */
  .gallery-grid {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    justify-items: center;
  }
  
  .gallery-item img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
  }
  