/* General Section Styling */
.highlights-section {
  padding: 40px;
  padding-top: 0px;
  text-align: center;
}

.highlights-title {
  font-size: 36px;
  font-family: 'SamsungSharpSans', arial, sans-serif;
  font-weight: bold;
  margin-bottom: 20px;
  white-space: nowrap; /* Force the title into one line */
}

/* Navigation Links */
.highlight-categories {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: -100px;
  overflow-x: auto; /* Allow horizontal scrolling */
  white-space: nowrap; /* Prevent categories from wrapping */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling for mobile */
}

.highlight-categories span {
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  padding-bottom: 5px;
}

.highlight-categories span:hover {
  text-decoration: underline;
}

/* Grid Layout */
.highlights-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr; /* 4 columns */
  grid-template-rows: repeat(2, auto); /* 2 rows */
  grid-gap: 30px 50px; /* General gaps for the grid */
  justify-items: center;
  align-items: center;
}

/* Small Images on the Left (2x2 grid) */
.highlight-item.small {
  height: 200px;
  width: 75%;
}

/* Large Images on the Right (2x2 grid) */
.highlight-item.large {
  height: 300px;
  width: 100%;
  margin-top: -5px; /* Extra gap above large images */
}

/* Specific margins for Image 1, 2, 5, and 6 */
.highlight-item.image1 {
  margin-top: 50px;
  margin-left: 20px;
}

.highlight-item.image2 {
  margin-top: 50px;
  margin-right: 150px;
}

.highlight-item.image5 {
  margin-bottom: 350px;
  margin-left: 20px;
  margin-top: -190px;
}

.highlight-item.image6 {
  margin-bottom: 350px;
  margin-right: 150px;
  margin-top: -190px;
}

/* Specific margins for Image 3, 4, 7, and 8 */
.highlight-item.image3 {
  margin-top: 150px;
  margin-left: -120px;
}

.highlight-item.image4 {
  margin-top: 150px;
  margin-right: 150px;
}

.highlight-item.image7 {
  margin-bottom: 250px;
  margin-left: -120px;
}

.highlight-item.image8 {
  margin-bottom: 250px;
  margin-right: 150px;
}

/* Image Container */
.image-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 10px; /* Ensure round corners */
  transition: transform 0.5s ease; /* Smooth zoom transition */
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px; /* Ensure round corners even during zoom */
  transition: transform 0.5s ease; /* Smooth zoom effect */
}

/* Zoom-in effect on hover */
.image-container:hover img {
  transform: scale(1.1); /* Smooth zoom-in effect */
}

/* Overlay Text */
.overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.Note {
  font-size: 24px;
  font-weight: bold;
  margin-right: 800px;
  margin-top: -380px;
}

.Note1 {
  font-size: 12px;
  font-weight: bold;
  margin-right: 800px;
  margin-top: -10px;
}

/* Mobile-specific styles */
@media (max-width: 768px) {

  /* Title styling: full width and font size 26px */
  .highlights-title {
    font-size: 26px;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  /* Set highlight categories to scroll horizontally (right to left) */
  .highlight-categories {
    display: flex;
    justify-content: flex-start;
    overflow-x: auto;
    gap: 5px;
    padding-bottom: 10px;
    white-space: nowrap;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
  }

  .highlight-categories span {
    font-size: 16px;
    font-weight: bold;
    padding: 5px 10px;
    cursor: pointer;
    white-space: nowrap;
    margin-bottom: 60px;
  }

  /* Grid Layout: Set grid for small images + text */
  .highlights-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 columns for small images */
    grid-gap: 10px;
    margin-top: 20px;
    margin-bottom: -200px;
  }

  /* Small images with new dimensions (width: 75%, height: 90px) and specific margins */
  .highlight-item.image1 {
    width: 100%;
    height: 120px;
    margin-top: 20px;
    margin-right: 40px;
    
  }

  .highlight-item.image2 {
    width: 100%;
    height: 120px;
    margin-top: 20px;
    margin-right: -20px;

  }

  .highlight-item.image3 {
    width: 100%;
    height: 120px;
    margin-top: 15px;
    margin-right: -100px;
    
  }

  .highlight-item.image4 {
    width: 100%;
    height: 120px;
    margin-top: 15px;
    margin-right: -20px;

  }


  /* Large images with new dimensions (width: 120%, height: 150px) and specific margins */
  .highlight-item.image5 {
    width: 100%;
    height: 120px;
    margin-top: 15px;
    margin-right: 40px;
  }

  .highlight-item.image6 {
    width: 100%;
    height: 120px;
    margin-top: 15px;
    margin-right: -20px;
  }

  .highlight-item.image7 {
    width: 100%;
    height: 120px;
    margin-top: -340px;
    margin-right: -100px;
  }

  /* Adjust margins to reduce the gap between the last image and the Note */
  .highlight-item.image8 {
    width: 100%;
    height: 120px;
    margin-top: -580px;
    margin-right: -20px;
    margin-bottom: 0px; /* Reduce bottom margin for the last image */
  }

  /* Ensure text alignment remains centered on mobile and reduce gap */
  .Note, .Note1 {
    font-size: 18px; /* Adjust font-size for Note */
    margin-top: 5px; /* Reduce the top margin of the Note */
    text-align: center;
    display: inline-block; /* Display elements inline */
    margin-right: 10px; /* Add some spacing between Note and Note1 */
    padding-top:-10px;
  }

  .Note1 {
    font-size: 12px; /* Smaller font size for Note1 */
    margin-top: 0; /* Remove additional margin */
  }
}
