.jersey-container {
    text-align: center;
    padding: 20px;
    background-color: #ffffff;
    padding-bottom: 260px;
}

.jersey-header {
    margin-bottom: 20px;
}

.jersey-subtitle {
    font-size: 1.2rem;
    color: #ff4500;
}

.jersey-title {
    font-size: 3rem;
    font-weight: bold;
    margin: 10px 0;
}

.jersey-hashtag {
    font-size: 1rem;
    color: #ff4500;
}

.jersey-grid {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    justify-items: center;
}

.jersey-item img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
}
