/* General slider setup */
.home {
  position: relative;
  width: 100%;
  height: 100%;
}

.slider {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.slider-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.fade-in {
  opacity: 1;
}

/* Previous and Next buttons */
.prev, .next {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  cursor: pointer;
  padding: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: background-color 0.3s ease;
}

.prev:hover, .next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.prev {
  left: 20px;
}

.next {
  right: 20px;
}

/* Slider indicators at the bottom */
.slider-indicators {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 60px;
  width: 100%;
}

.indicator__label-line {
  width: 120px;
  height: 3px;
  background-color: #ccc;
  margin: 0 5px;
  position: relative;
  overflow: hidden;
}

.indicator__label-line-filled {
  display: block;
  height: 100%;
  background-color: #686868;
  transition: width 5.5s ease-in-out;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .slider {
    height: auto;
  }

  .slider-image {
    height: auto;
  }

  .prev, .next {
    width: 30px;
    height: 30px;
  }

  .slider-indicators {
    bottom: 20px; /* Adjusted for smaller screens */
  }

  .indicator__label-line {
    width: 80px; /* Smaller indicator width for mobile */
  }

  /* Set GIF height specifically for mobile view */
  .slider-image[src*=".gif"] {
    height: 585px; /* Set GIF height to 585px for mobile */
  }
}

/* Mobile-specific styles for even smaller screens */
@media (max-width: 480px) {
  .prev, .next {
    width: 25px;
    height: 25px;
  }

  .indicator__label-line {
    width: 30px;
  }

  .slider-indicators {
    bottom: 50px; /* Further adjust for very small screens */
  }

  /* Ensure the same GIF height for very small screens */
  .slider-image[src*=".gif"] {
    height: 550px; /* Set GIF height to 585px for very small screens */
  }
}
